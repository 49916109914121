<template>
  <section class="usersCard">
    <router-link class="usersCard__wrapper" :to="{ name: 'generalInfo', params: { id: user._id }, query: $route.query }">
      <div class="usersCard__section usersCard__section--left">
        <div class="usersCard__avatarContainer" :stateAvatar="state">
          <Avatar class="usersCard__avatar" :user="user" />
          <span class="usersCard__statusCircle" :stateAvatar="state"></span>
        </div>
      </div>
      <div class="usersCard__sectionDataContent">
        <div class="usersCard__sectionDataContentDetails">
          <div class="usersCard__sectionData">
            <p class="usersCard__userName">{{ user.artisticName || user.user }}</p>
            <div class="usersCard__infoData ">
              <iconic name="user" />
              <span class="usersCard__text">{{ roleUser }}</span>
            </div>
            <div class="usersCard__infoData">
              <div class="usersCard__contentIcon">
                <span class="usersCard__icon">Ultima actividad</span>
                <iconic name="calendar" />
              </div>
              <span class="usersCard__text">{{ user.lastActivity ? $global.getElapsedTime(new Date(user.lastActivity)) : "Sin actividad" }}</span>
            </div>
          </div>
          <div class="usersCard__sectionData">
            <div class="usersCard__sectionDataContainer">
              <span class="usersCard__dataLocation">{{ user.city }}</span>
              <span class="usersCard__dataLocation" v-if="user.office && user.office !== 'null'">{{ user.office }}</span>
              <span class="usersCard__dataLocation" v-if="user.room && user.room !== 'null'">{{ user.room }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="usersCard__section usersCard__section--right">
        <iconic class="usersCard__flag" name="flag" v-if="user.redFlag" />
        <div class="usersCard__contentLogos" v-if="user.role === 'model'">
          <img
            class="usersCard__logo usersCard__logo--jasmin"
            src="https://staticx1.dditscdn.com/msc/jasmin/5afe5df7283c72ee0d9d.svg"
            alt="jasmin"
            v-if="user.jasminUser"
          />
          <img class="usersCard__logo usersCard__logo--streamate" src="../../../assets/img/str.png" alt="streamate" v-if="user.streamateUser" />
        </div>
      </div>
    </router-link>
  </section>
</template>

<script>
export default {
  props: ["user"],
  computed: {
    state() {
      const { isActive, isOnline } = this.user;
      return isActive && isOnline ? "online" : !isActive && isOnline ? "away" : "offline";
    },
    roleUser() {
      const role = this.user.rolesApp.autologin[0];
      const roleInver = this.$global.dictionary.rolesInver[role];
      return this.$global.dictionary.roles_es_auto[roleInver];
    },
  },
};
</script>

<style lang="scss">
.usersCard {
  width: 100%;
  a {
    text-decoration: none;
    color: black;
  }

  &__wrapper {
    @include Flex(row, space-between);
    flex-wrap: nowrap;
    width: 100%;
    padding: 10px 15px;
    gap: $mpadding / 1.5;
    user-select: none;
    border-radius: 6px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    background: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      background: #f4f4f4;
    }
  }
  &__section {
    width: 50px;
    &--right {
      @include Flex(column);
      width: 50px;
    }
  }
  &__sectionDataContent {
    width: calc(100%);
  }
  &__sectionDataContentDetails {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    width: 100%;
    gap: 15px;
    margin-top: 2px;
  }

  &__sectionData {
    @include Flex(column, flex-start, flex-start);
    width: 100%;
    max-width: 100%;
    gap: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__sectionDataContainer {
    @include Flex(column, flex-start, flex-start);
    gap: 4px;
  }
  &__infoData {
    @include Flex(row, flex-start);
    gap: 5px;
    font-size: 15px;
  }
  &__avatarContainer {
    position: relative;
    width: fit-content;
    border: 3px solid $alto;
    border-radius: 50%;
    &[stateAvatar="online"] {
      border-color: $la_palma;
    }
    &[stateAvatar="away"] {
      border-color: $tree_Poppy;
    }
  }
  &__avatar {
    width: 42px;
    height: 42px;
    font-size: 15px;
    border: 2px solid $white;
  }
  &__statusCircle {
    position: absolute;
    width: 10px;
    height: 10px;
    right: 2px;
    bottom: 2px;
    border: 2px solid $white;
    border-radius: 50%;
    background-color: $alto;
    &[stateAvatar="online"] {
      background-color: $la_palma;
    }
    &[stateAvatar="away"] {
      background-color: $tree_Poppy;
    }
  }
  &__userName {
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__text {
    text-overflow: ellipsis;
    text-transform: capitalize;
  }
  &__contentIcon {
    position: relative;
  }
  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px 5px;
    font-size: 11px;
    white-space: nowrap;
    visibility: hidden;
    color: #fff;
    background-color: #000;
    border-radius: 4px;
  }
  &__contentIcon:hover &__icon {
    visibility: visible;
  }
  &__dataLocation {
    @include Flex(row);
    width: 100%;
    padding: 0 15px;
    border-radius: 15px;
    border: 1px solid rgba(189, 9, 9, 0.6);
    color: rgba(189, 9, 9, 0.6);
    font-weight: 600;
    font-size: 14px;
  }
  &__flag {
    color: $primary-color;
    font-size: 1.7em;
  }
  &__contentLogos {
    @include Flex(row, flex-end);
    width: 100%;
    margin-top: 5px;
    gap: 5px;
  }
  &__logo {
    @include Flex(row);
    width: 22.5px;
    height: 22.5px;
    padding: 2px;
    border-radius: 4px;
    &--streamate {
      background: #21619a;
      color: white;
    }
    &--jasmin {
      background-color: $primary-color;
      color: white;
    }
  }
}
</style>
